import React, {useState} from 'react';
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const msMaxWait = 2000;

const ContactPage = () => {
	const [timerPhoneNumber, setTimerPhoneNumber] = useState(0);
	const [counterPhoneNumber, setCounterPhoneNumber] = useState(0);
	const [phoneNumber, setPhoneNumber] = useState('click 3 times');
	const [hrefPhoneNumber, setHrefPhoneNumber] = useState('#'); // later/below tests for this value

	const handlePhoneClick = e => {
		console.log('running');
		if(hrefPhoneNumber === "#") e.preventDefault();
		clearTimeout(timerPhoneNumber);
		setCounterPhoneNumber(prevCount => prevCount + 1);
		if(counterPhoneNumber < 2){
			setTimerPhoneNumber(
				() => setTimeout( () => clearTimeout(timerPhoneNumber), msMaxWait )
			);

		}else{
			setCounterPhoneNumber(() => 0);
			setPhoneNumber(() => process.env.GATSBY_MY_PHONE_NUMBER);
			navigator.clipboard.writeText(process.env.GATSBY_MY_PHONE_NUMBER).then(
				() => {
					setPhoneNumber( () => `copied to clipboard: ${process.env.GATSBY_MY_PHONE_NUMBER}` );
					setHrefPhoneNumber( () => `tel:${process.env.GATSBY_MY_PHONE_NUMBER.replaceAll(' ', '')}` );
				}
			);
		}
	};

	return (
		<Layout>
			<Seo title="Contact" />
			<h2>Contact</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/IMGP1843.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>
			<h3>Get in touch</h3>
			<p>
				For booking, licensing, collaborations, comments, etc., just email <a
				href="mailto:jesse@jesserivest.com"
				>jesse@jesserivest.com</a>.<br />
				I check my spam folder.
			</p>
			<p>
				You can even leave me a voice message at <a
				href={hrefPhoneNumber}
				onClick={handlePhoneClick}
				onKeyDown={handlePhoneClick}
				>{phoneNumber}
				</a> (just leave your message after the beep).<br />
				I assure you I'll receive it.
			</p>
		</Layout>
	);
}

export default ContactPage;
